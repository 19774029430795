import { EQuestionItemSubType, EQuestionItemType, EQuestionOptionStatus, IQuestionItem, IQuestionMatrixResRow, IQuestionMatrixStyle, IQuestionOptionItem, IQuestionOptionStyle, IQuestionResItem, IQuestionResMatrix, IQuestionResOption, IQuestionResOptionItem, IQuestionResSensitiveInput, IQuestionResStar, IQuestionResText } from '@/interface/survey';
import { isNumber } from '@/utils/number-ext';

/**
 * 根据问题列表，生成提交需要的保存结果列表
 * @param questions 
 */
export function getQuestionResItems(questions: IQuestionItem[]) {
  const res: IQuestionResItem[] = questions.map((question) => {
    if (question.type === EQuestionItemType.Star) {
      // 量表题默认选中2分
      // const value = question.options?.filter(c => c.id === '2') ?? [];
      const res: IQuestionResStar = {
        type: question.type,
        model: question,
        value: [],
      };
      return res;
    }
    if (question.type === EQuestionItemType.Option) {
      const res: IQuestionResOption = {
        type: question.type,
        model: question,
        value: [],
      };
      return res;
    }
    if (question.type === EQuestionItemType.Text) {
      const res: IQuestionResText = {
        type: EQuestionItemType.Text,
        model: question,
        value: '',
      };
      return res;
    }
    if (question.type === EQuestionItemType.Matrix) {
      const value: IQuestionMatrixResRow[] = question.sub_titles.map(sub_title => {
        const { id, text } = sub_title;
        const options = question.options.map(option => {
          const { id, text } = option;
          const optionRes: IQuestionResOptionItem = {
            id,
            text,
            status: EQuestionOptionStatus.UNCHOOSED,
          };
          return optionRes;
        });
        return {
          id,
          text,
          options,
        };
      });
      const res: IQuestionResMatrix = {
        type: EQuestionItemType.Matrix,
        model: question,
        value,
      };
      return res;
    }
    const res: IQuestionResSensitiveInput = {
      type: EQuestionItemType.SensitiveInput,
      model: question,
      value: '',
      confirm: false,
    };
    return res;
  });
  return res;
}

export interface IDisplayTreeNode {
  id: string;
  indexPath: number[];
  children: IDisplayTreeNode[];
}

/**
 * 根据问题中的displays获取显示节点
 * @param questions 
 * @returns 
 */
export function getDisplayTreeNodesByQuestions(questions: IQuestionItem[]) {
  // 如果问题存在于任何一个选项中的显示逻辑(displays)中，则需要隐藏(不添加到res中)
  const nodes: IDisplayTreeNode[] = questions.reduce((res: IDisplayTreeNode[], question, index) => {
    const { id: itemId } = question;
    const questionIndex = questions.findIndex(c => {
      // 有options的题型
      if (c.type === EQuestionItemType.Star || c.type === EQuestionItemType.Option) {
        const optionIndex = c.options.findIndex(o => {
          const displayIndex = o.displays.findIndex(display => display === itemId);
          return displayIndex > -1;
        });
        return optionIndex > -1;
      }
      return false;
    });
    // 不存在于任意一个displays中，常驻显示
    if (questionIndex === -1) {
      const displayTreeNode: IDisplayTreeNode = {
        id: itemId,
        indexPath: [index],
        children: [],
      };
      return [...res, displayTreeNode];
    }
    return res;
  }, []);
  return nodes;
};

/**
 * 校验提交结果是否满足
 * @param resQuestions 
 * @returns 
 */
export function verifyResQuestions(resQuestions: IQuestionResItem[]) {
  const errors = resQuestions.reduce((res: string[], item, index) => {
    const { type, value, model } = item;
    if (item.model.required) {
      if (type === EQuestionItemType.Star || type === EQuestionItemType.Option) {
        if (value.length === 0) {
          const errorMsg = `问题${index + 1}，尚未选择。请勾选后提交~`;
          return [...res, errorMsg];
        }
        if (type === EQuestionItemType.Option) {
          const { style } = model;
          const styleObj: IQuestionOptionStyle = style ? JSON.parse(style.replace(/: "/g, ':"')) : {
            line_items: '3',
          };
          const { max } = styleObj;
          if (max !== undefined && max !== '' && isNumber(max)) {
            if (value.length > Number(max)) {
              const errorMsg = `问题${index + 1}，最多可选${max}个哦~`;
              return [...res, errorMsg];
            }
          }
        }
      }
      if (type === EQuestionItemType.Matrix) {
        let subTitles = model.sub_titles;
        // 存在引用的父问题
        const referAnswer = resQuestions.find(c => c.model.id === model.refer);
        if (referAnswer?.model?.sub_type === EQuestionItemSubType.CHECKBOX) {
          // 根据父问题已选中的选项作为行标题
          const referAnswerOptions = referAnswer.value as IQuestionOptionItem[];
          subTitles = referAnswerOptions.map(option => ({ ...option, is_deleted: !!option.is_deleted }));
        }
        // 异常行索引
        const errRowIndex = subTitles.findIndex(sub_title => {
          // // 屎山雕花：因为矩阵题的id是自增的，父问题的option.id也是自增，所以不能使用id进行匹配，只能约束多选题文本不能一样，并使用text匹配
          const rowModel = value.find(k => k.text === sub_title.text);
          if (rowModel) {
            const chooseArr = rowModel.options.filter(c => c.status === EQuestionOptionStatus.CHOOSED);
            return chooseArr.length === 0;
          }
          return false;
        });
        if (errRowIndex !== -1) {
          const errorMsg = `问题${index + 1}第${errRowIndex + 1}行：尚未选择。请勾选后提交~`;
          return [...res, errorMsg];
        }
        const { style } = model;
        const styleObj: IQuestionMatrixStyle = style
          ? JSON.parse(style.replace(/: "/g, ':"'))
          : {
            display_style: 'matrix',
          };
        const { max } = styleObj;
        if (max !== undefined && max !== '' && isNumber(max)) {
          const rowIndex = value.findIndex(row => row.options.filter(col => col.status === EQuestionOptionStatus.CHOOSED).length > Number(max));
          if (rowIndex !== -1) {
            const errorMsg = `问题${index + 1}第${rowIndex + 1}行：最多可选${max}个哦~`;
            return [...res, errorMsg];
          }
        }
      }
      if (type === EQuestionItemType.Text) {
        if (value.length === 0 || value === undefined || value === '') {
          const errorMsg = `问题${index + 1}，尚未填写哦。请填写后提交~`;
          return [...res, errorMsg];
        }
      }
      if (type === EQuestionItemType.SensitiveInput) {
        if (!item.confirm) {
          const errorMsg = `问题${index + 1}，尚未勾选协议，请勾选后再提交`;
          return [...res, errorMsg];
        }
        if (/^\d{11}$/.test(item.value)) {
          const errorMsg = `问题${index + 1}，请输入有效的手机号码`;
          return [...res, errorMsg];
        }
      }
    }
    if (type === EQuestionItemType.SensitiveInput) {
      if (item.value.length > 0 && !/^\d{11}$/.test(item.value)) {
        const errorMsg = `问题${index + 1}，请输入有效的手机号码`;
        return [...res, errorMsg];
      }
    }
    return res;
  }, []);
  return errors;
}

/**
 * 获取最大可选提示元素
 * @param max 最大可选数
 * @param required 是否必填
 * @param prefix 前置文案
 * @returns
 */
export function getMaxTipsEle(max?: string, required?: boolean, prefix?: string) {
  const prefixTitle = prefix ?? '';
  // 多选情况，但没有最大可选数时，并且必填
  if (max === undefined && required) {
    return <div class='mt-12px c-666'>
      {prefixTitle}请至少选择1项
    </div>;
  }
  // 最大可选为1时，并且必填
  if (max?.toString() === '1' && required) {
    return <div class='mt-12px c-666'>
      {prefixTitle}请选择1项
    </div>;
  }
  // 存在最大可选数
  if (max !== undefined) {
    return <div class='mt-12px c-666'>
      {required ? `${prefixTitle}请选1-${max}项` : `${prefixTitle}最多可选${max}项`}
    </div>;
  }
  return null;
}


/**
 * 根据问题子类型返回标题
 * @param subType
 * @returns
 */
export function getSubTypeTitle(question: IQuestionItem) {
  const { sub_type } = question;
  switch (sub_type) {
    case EQuestionItemSubType.CHECKBOX:
    case EQuestionItemSubType.IMAGE_CHECKBOX:
    case EQuestionItemSubType.MATRIX_CHECKBOX:
      return '多选';
    case EQuestionItemSubType.RADIO:
    case EQuestionItemSubType.IMAGE_RADIO:
    case EQuestionItemSubType.MATRIX_RADIO:
      return '单选';
    default:
      return '';
  }
}

/**
 * 获取当前题目是单选还是多选
 * @param max
 * @returns
 */
export function getOptionTypeEle(question: IQuestionItem) {
  const title = getSubTypeTitle(question);
  return <span class='question-title-tag fs-14px'>{title}</span>;
}
