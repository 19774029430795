import Vue from 'vue';
import Router from 'vue-router';

// import CSAT from '@/view/CSAT';
import Csat from '@/view/Csat';
import CsatOverflow from '@/view/CsatOverflow';
import Nps from '@/view/Nps';
import NpsOverflow from '@/view/NpsOverflow';
import SurveyPreview from '@/view/SurveyPreview';

Vue.use(Router);

export const constantRouterMap = [
  {
    path: '',
    component: Csat,
  },
  {
    path: '/tux/NPS',
    component: Nps
  },
  {
    path: '/tux/overflow/NPS',
    component: NpsOverflow
  },
  {
    path: '/tux/CSAT',
    component: Csat
  },
  {
    path: '/tux/overflow/CSAT',
    component: CsatOverflow
  },
  {
    path: '/survey-preview',
    component: SurveyPreview,
    name: 'SurveyPreview',
  },
  {
    path: '/survey-success-view',
    component: () => import('@/view/SurveySuccessView'),
    name: 'SurveySuccessView',
  },
];

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});
