import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import TDesign from 'tdesign-vue';

import '@/styles/index.scss'; // global css

import App from './App.vue';

import router from '@/router';

import { beacon } from '@/utils/beacon';
import VueCompositionAPI from '@vue/composition-api';
// import { flexible } from './utils/font-size-helper';
// import { remFix } from './utils/rem-fix';
// flexible(860);
// remFix(860);

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(TDesign);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

import '@/assets/css/base.less';
import '@/assets/css/td-ext.less';

function getQueryArgs() {
  const hash = window.location.hash.substring(1); // "/tux/NPS?survey_id=134&uuid=xxx"
  const obj = hash.split('?')?.[1]?.split("&")?.reduce(function (prev, curr) {
    var pair = curr.split("=");
    prev[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    return prev;
  }, {}) ?? {};
  return obj;
}

async function getQ36() {
  return new Promise((reslove) => {
    if (window?.browser?.app?.getBrowserParam) {
      window?.browser?.app?.getBrowserParam((params) => {
        const { qimei36 } = params;
        reslove(qimei36);
      });
      return;
    }
    reslove('');
  });
}

console.log('CLINT_ENV:', window.ENV_CONSTANT.CLINT_ENV);

const startTime = performance.now();
window.addEventListener('beforeunload', async function () {
  const endTime = performance.now();
  const time = endTime - startTime;
  const queryArgs = getQueryArgs();
  let survey_type = '';
  const hash = window.location.hash.substring(1)
  if (hash.toLocaleLowerCase().indexOf('csat') > -1) {
    survey_type = 'csat';
  }
  if (hash.toLocaleLowerCase().indexOf('nps') > -1) {
    survey_type = 'nps';
  }
  const qimei36 = await getQ36();
  beacon.onDirectUserAction('tux_h5_logout', {
    env: window.ENV_CONSTANT.CLINT_ENV,
    iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
    survey_id: queryArgs?.survey_id,
    survey_type,
    business: queryArgs?.app_id ?? '',
    user_id: qimei36,
    duration_time: time,
  });
});

window.addEventListener('load', function () {
  let survey_type = '';
  const hash = window.location.hash.substring(1)
  if (hash.toLocaleLowerCase().indexOf('csat') > -1) {
    survey_type = 'csat';
  }
  if (hash.toLocaleLowerCase().indexOf('nps') > -1) {
    survey_type = 'nps';
  }
  var performance = window.performance;
  if (!performance) {
    // 当前浏览器不支持
    console.log('你的浏览器不支持 performance 接口');
    beacon.onDirectUserAction('tux_h5_init', {
      router: window.location.href,
      env: window.ENV_CONSTANT.CLINT_ENV,
      iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
      // @ts-ignore
      tux_uuid: queryArgs?.uuid,
      // @ts-ignore
      survey_id: queryArgs?.survey_id,
      survey_type,
      server_type: 'H5',
      loading_time: '-1',
      app_id: queryArgs?.app_id ?? 'qb',
    })
    return;
  }
  const navEntry = performance.getEntriesByType("navigation")[0];
  // @ts-ignore
  const loading_time = navEntry.domInteractive - navEntry.fetchStart;
  const queryArgs = getQueryArgs();
  beacon.onDirectUserAction('tux_h5_init', {
    router: window.location.href,
    env: window.ENV_CONSTANT.CLINT_ENV,
    iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
    // @ts-ignore
    tux_uuid: queryArgs?.uuid,
    // @ts-ignore
    survey_id: queryArgs?.survey_id,
    survey_type,
    server_type: 'H5',
    loading_time,
    app_id: queryArgs?.app_id ?? 'qb',
  })
});
